import $ from 'jquery';
import Rails from "rails-ujs";

// Based on:
// https://stackoverflow.com/a/55115922/907599
$(document).on('change', '.custom-file-input', function(event){
  var filenames = $.map(event.target.files, function(file){ return file.name }).join(', ');
  $(this).next('.custom-file-label').html(filenames);
});

// Initialise direct upload
require("@rails/activestorage").start();

// Based on:
// https://guides.rubyonrails.org/active_storage_overview.html#direct-upload-javascript-events
$(document).on('direct-upload:initialize', function(event){
  const button = $('form button');
  $.each(button, function(index, elem){ Rails.disableElement(elem); });
  button.attr('disabled', 'disabled');

  const { target, detail } = event;
  const { id, file } = detail;
  $('#direct-upload-progress').addClass('mt-3').append(`
    <li class="list-group-item d-flex justify-content-between align-items-center" id="direct-upload-${id}">
      ${file.name}
      <span id="direct-upload-progress-${id}" class="badge badge-pill">Pending</span>
    </li>
  `);
});

$(document).on('direct-upload:start', function(event){
  const { id } = event.detail;
  const element = $(`#direct-upload-progress-${id}`);
  element.removeClass("badge-light").addClass("badge-info");
  element.html("Starting");
});

$(document).on('direct-upload:progress', function(event){
  const { id, progress } = event.detail;
  const element = $(`#direct-upload-progress-${id}`);
  element.html(`${Math.round(progress)}% uploaded`);
});

var failedUploads = [];

$(document).on('direct-upload:error', function(event){
  event.preventDefault();

  const { id, error } = event.detail;
  failedUploads.push(id);

  const element = $(`#direct-upload-progress-${id}`);
  element.removeClass("badge-info").addClass("badge-danger");
  element.attr("title", error);
  element.html("Failed");
});

$(document).on('direct-upload:end', function(event){
  const { id } = event.detail;

  // Don't mark as completed if the upload failed
  if (failedUploads.includes(id)){
    return;
  }

  const element = $(`#direct-upload-progress-${id}`);
  element.removeClass("badge-info").addClass("badge-success");
  element.html("Uploaded");
});

$(document).on('direct-uploads:end', function(){
  const button = $('form button');
  $.each(button, function (index, elem) { Rails.enableElement(elem); });
  button.removeAttr('disabled');
});
