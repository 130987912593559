import $ from 'jquery';

$(document).ready(function() {
  $('#filter-body').on('show.bs.collapse', function(e) {
    $('a[href="#filter-body"] i').attr('class', $('a[href="#filter-body"]').data('hide-icon'));
  });

  $('#filter-body').on('hide.bs.collapse', function() {
    $('a[href="#filter-body"] i').attr('class', $('a[href="#filter-body"]').data('show-icon'));
  });
});
