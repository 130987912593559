import $ from 'jquery';

class FlashAlert {
  constructor(message, type) {
    this.message = message;
    this.type = type || 'info';
  }

  static display(message, type) {
    return new FlashAlert(message, type).display();
  }

  display() {
    const alert = $(`
      <div class="alert alert-${this.type} in">
        ${this.message}
        <button class="close" aria-label="close" data-dismiss="alert" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `);

    alert.data('timeout', setTimeout(() => alert.alert('close'), 20000));

    $('.flash-messages').prepend(alert)
  }

  numberOfAlertsVisible() {
    return $('.flash-messages .alert').length;
  }
}

export default FlashAlert;