import * as Sentry from "@sentry/browser";
import $ from 'jquery';

// Notify Sentry of any JS errors
if ( process.env.NODE_ENV === 'production' ) {
  const dsn = $('head').data('sentry-dsn')
  Sentry.init({
    dsn: dsn
  });
}
