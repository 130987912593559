import $ from 'jquery';

$(document).ready(function() {
  $('.select2').trigger('init-select2');

  // Fix placeholder not displaying correctly in Firefox
  $('.collapse').on('shown.bs.collapse', function() {
    $('select').trigger('change');
  });
});

$(document).on('init-select2', '.select2', function() {
  var options = {
    allowClear: true,
    placeholder: ($(this).attr('placeholder') ? $(this).attr('placeholder') : 'Please select an option'),
    width: ($(this).data('width') ? $(this).data('width') : '100%'),
    theme: "bootstrap"
  };

  $(this).select2(options);

  $(this).on("select2:unselect", function (evt) {
    $(this).on("select2:opening.cancelOpen", function (evt) {
      evt.preventDefault();

      $(this).off("select2:opening.cancelOpen");
    });
  });
});
