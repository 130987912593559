/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We're including this file in app/views/layouts/application.html.haml - it
// should mostly only include application-wide Javascript. You should separate
// your Javascript into sensible files / components then make a decision how best
// to compose them to serve to the client.
//
// For example:
// The home page has a specific slideshow, some sort of calculator, an ad rotator etc.
// You could split these into calculator.js, slideshow.js and rotator.js then
// include them in a home.js pack which is only included on the home page.

import '../sentry/init';

import Rails from "rails-ujs";
import 'bootstrap';
import 'select2';
import './application_styles';
import '../select2/init';
import '../bootstrap-file-input/init';
import '../audit-logging/toggle-filters';
import FlashAlert from '../alerts/flash_alert';

// make availbale in views
window.FlashAlert = FlashAlert;

Rails.start();
